<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#68D389"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#68D389" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #ffffff">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #ffffff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-end>
      <v-flex lg12>
        <v-card tile flat>
          <v-layout wrap justify-start pa-4>
            <v-flex xs12 pt-6 pb-4 align-self-center text-left>
              <span style="font-family: poppinsbold; font-size: 20px">
                Employee Details
              </span>
            </v-flex>

            <v-flex xs12 sm6 pa-2 align-self-start text-left>
              <span style="font-family: poppinsbold"> Name </span>

              <v-text-field
                v-model="user.name"
                hide-details
                dense
                readonly
              ></v-text-field>
            </v-flex>

            <v-flex xs12 sm6 pa-2 align-self-start text-left>
              <span style="font-family: poppinsbold">Role</span>

              <v-text-field
                hide-details
                v-model="user.role"
                dense
                readonly
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 pa-2 align-self-start text-left>
              <span style="font-family: poppinsbold"> Phone Number </span>

              <v-text-field
                type="number"
                hide-details
                v-model="user.phonenumber"
                placeholder="Phone Number"
                dense
                readonly
              ></v-text-field>
            </v-flex>

            <v-flex xs12 sm6 pa-2 text-left>
              <span style="font-family: poppinsbold">Date of birth</span>

              <v-text-field
                hide-details
                v-model="user.dob"
                placeholder="Date of Birth"
                dense
                readonly
                @wheel="$event.target.blur()"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 pa-2 text-left>
              <span style="font-family: poppinsbold">Gender</span>

              <v-text-field
                hide-details
                v-model="user.gender"
                placeholder="Gender"
                dense
                readonly
                class="text-capitalise"
              ></v-text-field>
            </v-flex>

            <v-flex xs12 align-self-center text-left pa-2 pt-6 v-if="logDet">
              <span style="font-size:18px" class="poppinsbold"
                >Log Details</span
              >
            </v-flex>

            <v-flex xs12 pa-2 v-if="logDet.length>0">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr class="poppinsbold">
                      <th class="text-left">
                        Date
                      </th>
                      <th class="text-left">
                        Assigned Unit
                      </th>
                      <th class="text-left">
                        Assigned By
                      </th>
                    </tr>
                  </thead>
                  <tbody class="text-left poppinsregular">
                    <tr v-for="(item,i) in logDet" :key="i">
                      <td>{{ formatDate(item.create_date) }}</td>
                      <td>
                        <span v-if="item.unitId.name">
                          {{ item.unitId.name }}
                        </span>
                        <span v-else>-</span>
                      </td>
                      <td>
                        <span v-if="item.assignPerson">
                          {{ item.assignPerson }}
                      
                        </span>
                        <span v-else>-</span>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-flex>
            <v-flex xs12 pa-2 v-else>
              <span>
                Employee is not assigned anywhere!
              </span>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      menu1: false,
      units: null,
      program: "",
      types: null,
      type: null,
      icId: null,
      roles: [],
      name: null,
      description: null,
      user: {
        name: null,
        gender: null,
        dob: null,
        role: null,
        phonenumber: null,
        // password: null,
      },
      activePicker: null,
      menu: false,
      show1: false,
      logDet: null,
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length <= 10 || "Max 10 digits",
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
        min: (value) => value.length == 10 || "Invalid Phone",
      },
    };
  },
  beforeMount() {
    this.getData();
    this.getLog();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/view/admin/profile/employee",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: this.$route.query.id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.user.name = response.data.data.userName;
          this.user.phonenumber = JSON.parse(response.data.data.phoneNumber);
          this.user.role = response.data.data.role;
          this.user.gender = response.data.data.gender;
          this.user.dob = response.data.data.dob.slice(0, 10);
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    getLog() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/employee/log",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          empId: this.$route.query.id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.logDet = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    validateInput() {
      if (!this.user.name) {
        this.msg = "Please Provide Name";
        this.showSnackBar = true;
        return;
      } else if (!this.user.role) {
        this.msg = "Please specify Role";
        this.showSnackBar = true;
        return;
      } else if (!this.user.gender) {
        this.msg = "Please Provide Gender";
        this.showSnackBar = true;
        return;
      } else if (!this.user.dob) {
        this.msg = "Please Provide DOB";
        this.showSnackBar = true;
        return;
      } else if (!this.user.phonenumber) {
        this.msg = "Please Provide Phone No";
        this.showSnackBar = true;
        return;
      } else if (!this.$route.query.id) {
        this.msg = "Please Provide User id";
        this.showSnackBar = true;
        return;
      } else {
        this.editEmp();
      }
    },

    
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      dt = dt.toString();
      var strTime = dt.slice(4, 7) + " " + day + " " + year + " ";
      return strTime;
    },
  },
};
</script>
<style>
input {
  color: black !important;
}
</style>
